import { WINDOWS_MDM, MAC_MDM } from '../constants';

export const getMDMFromNavigator = () => {
  let appMDM = null;
  const { platform } = window.navigator;

  if (platform.indexOf('Win') !== -1) {
    appMDM = WINDOWS_MDM;
  } else if (platform.indexOf('Mac') !== -1) appMDM = MAC_MDM;

  return appMDM;
};
