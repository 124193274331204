import { useEffect } from 'react';
import { WINDOWS_MDM, MAC_MDM } from '../../constants';

export function Downloads({ links }) {
  const { [MAC_MDM]: macLink, [WINDOWS_MDM]: windowsLink } = links;

  useEffect(() => {
    const { [MAC_MDM]: macLink, [WINDOWS_MDM]: windowsLink } = links;
    if (!macLink && !windowsLink) {
      return;
    }
    const downloadLink = document?.querySelector(
      `a[href='${macLink || windowsLink}']`
    ) as HTMLAnchorElement;
    downloadLink.click();
  }, [links]);
  return (
    <div>
      <h1>Downloading your software</h1>
      <h2>
        Trouble downloading? Download for{' '}
        {macLink && (
          <a href={macLink} download>
            Mac
          </a>
        )}{' '}
        {macLink && windowsLink && '|'}{' '}
        {windowsLink && (
          <a href={windowsLink} download>
            PC
          </a>
        )}
      </h2>
    </div>
  );
}
