import React from 'react';
import DownloadDropdownButton from './DownloadDropdownButton';
import './DesktopAppPage.css';

const DesktopAppPage = () => {
  return (
    <div className="app-div">
      <div className="app-text-container">
        <img
          alt="desktop app download icon"
          src="/assets/images/appDownload.svg"
        />
        <h1 className="app-h1 app-text">Download Electric</h1>
        <p className="app-p app-text">
          Download the Electric application to get started! Electric can provide
          insights for your company around your device's performance and
          security.
        </p>
        <DownloadDropdownButton />
      </div>
    </div>
  );
};

export default DesktopAppPage;
