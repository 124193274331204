export const ARCHITECTURES = {
  ARM: 'arm',
  INTEL: 'intel',
};

export const getIsMac = () =>
  navigator.platform.toLowerCase().indexOf('mac') >= 0;

export const _detectArchitectureFromRendererString = (
  rendererString: string
) => {
  if (
    rendererString.toLowerCase().includes('vulkan') ||
    rendererString.toLowerCase().includes('intel')
  ) {
    return ARCHITECTURES.INTEL;
  } else {
    return ARCHITECTURES.ARM;
  }
};

export const getMacArchitecture = () => {
  const isMac = getIsMac();
  if (!isMac) {
    throw new Error('Device is not running Mac OS');
  }

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const webglContext = document.createElement('canvas').getContext('webgl');
  if (!isSafari) {
    const debugRendererInfo = webglContext?.getExtension(
      'WEBGL_debug_renderer_info'
    );
    const renderer =
      (debugRendererInfo &&
        webglContext?.getParameter(
          debugRendererInfo.UNMASKED_RENDERER_WEBGL
        )) ||
      '';
    return _detectArchitectureFromRendererString(renderer);
  } else {
    return webglContext
      ?.getSupportedExtensions()
      ?.indexOf('WEBKIT_WEBGL_compressed_texture_pvrtc') === -1
      ? ARCHITECTURES.INTEL
      : ARCHITECTURES.ARM;
  }
};

export const getSemanticVersionFromWindowsRelease = (releaseText: string) => {
  // Example Windows release text: '59091948A3EEF8D6DFA9B160249EBE61DAB143C3 electric_desktop_app-0.0.1-full.nupkg 161156547'
  // From there we will pull out the semantic version e.g. 0.0.1
  // Split by newline and grab the last line
  const lines = releaseText.split('\n');
  const lastLine = lines[lines.length - 1];
  const regex = /electric_desktop_app-(.*?)-full.nupkg/; // Match the semantic version
  const match = lastLine.match(regex);
  if (!match || match.length < 2) {
    throw new Error('Error parsing semantic version from release text');
  }
  return match[1];
};
