import React, { useState, useEffect } from 'react';
import { Downloads, Error } from '..';
import { getLinksForCustomer } from '../../services';
import { getCustomerIDFromURL, getMDMFromNavigator } from '../../helpers';
import '../../App.css';

const MDMPage = () => {
  const [error, setError] = useState(false);
  const [links, setLinks] = useState({});

  useEffect(() => {
    const customerID = getCustomerIDFromURL();
    const customerMDM = getMDMFromNavigator();
    if (!customerID || !customerMDM) {
      setError(true);
      return;
    }
    getLinksForCustomer(customerID as string)
      .then((fetchedLinks) => {
        if (fetchedLinks && fetchedLinks[customerMDM]) {
          setError(false);
          setLinks(fetchedLinks);
        } else {
          setError(true);
        }
      })
      .catch(() => setError(true));
  }, []);

  if (error === null) return null;

  return (
    <div>
      <div className="header">
        <img
          alt="logo"
          className="logo"
          src="/assets/images/logo_electric_white.png"
        />
      </div>
      <div className="content">
        {error ? <Error /> : <Downloads links={links} />}
        <div className="content-block">
          <div className="content-image">
            <img alt="lightning" src="/assets/images/lightning.png" />
          </div>
          <p>
            Device Management software helps Electric troubleshoot your devices
            and quickly resolve issues so you can get back to what's important.
            Installing should take 5 minutes and won't require any restarts!
          </p>
        </div>
        <div className="content-block">
          <div className="content-image">
            <img alt="shield" src="/assets/images/shield.png" />
          </div>
          <p>
            Slow computer, apps crashing or trouble with wifi? Electric can
            often resolve these issues in a matter of minutes. Once your device
            is set up, you can slack Electric for real-time support.
          </p>
        </div>
        <div className="content-block">
          <p>
            Have a question? Email us at
            <a href="mailto:onboarding-team@electric.ai">
              {' '}
              onboarding-team@electric.ai
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MDMPage;
