import React, { useEffect } from 'react';
import './DisconnectAppPage.css';

const DisconnectAppButton = () => {
  return (
    <a
      href="electric-desktop://disconnect-device"
      id="open-desktop-app-disconnect">
      Open the Electric application
    </a>
  );
};

const DisconnectAppPage = () => {
  useEffect(() => {
    window.location.href = 'electric-desktop://disconnect-device';
  });
  return (
    <div className="app-div">
      <div className="app-text-container">
        <img
          alt="desktop app disconnect"
          src="/assets/images/logo_electric_white.png"
        />
        <h1 className="app-h1 app-text">How to unenroll your device:</h1>
        <p className="app-p app-text">
          <ol>
            <li>
              If a pop-up appears above, click the "Open Electric Desktop"
              button. If no pop-up appears, click the "Open the Electric
              application" button below to display the pop-up.
            </li>
            <li>Log in to your Electric account using your credentials.</li>
            <li>
              Click on the "Disconnect device" button to unenroll your device.
            </li>
          </ol>
        </p>
        <div className="disconnect-app-button">
          <DisconnectAppButton />
        </div>
      </div>
    </div>
  );
};

export default DisconnectAppPage;
