export function Error() {
  return (
    <div>
      <h1>Something went wrong</h1>
      <h2>
        Please contact us at{' '}
        <a href="mailto:onboarding-team@electric.ai">
          onboarding-team@electric.ai
        </a>
      </h2>
    </div>
  );
}
