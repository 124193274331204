import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  getIsMac,
  getMacArchitecture,
  getSemanticVersionFromWindowsRelease,
  ARCHITECTURES,
} from './file-extension-helpers';

import './DownloadDropdownButton.css';

const { REACT_APP_DESKTOP_APP_S3_BUCKET_URL } = process.env;

const Versions = ['mac-arm', 'mac-intel', 'win'] as const;
type Version = (typeof Versions)[number];

type DownloadURLsMap = Record<Version, string>;

const getDownloadURLWindows = async (): Promise<string> => {
  const architectureURL = `${REACT_APP_DESKTOP_APP_S3_BUCKET_URL}/win32/x64`;
  const url = `${architectureURL}/RELEASES`;
  const response = await fetch(url);
  if (response.status !== 200) {
    throw new Error('Error fetching releases JSON');
  }
  const releaseText = await response.text();
  const semanticVersion = getSemanticVersionFromWindowsRelease(releaseText);
  return `${architectureURL}/Electric Desktop-${semanticVersion} Setup.exe`;
};

const getDownloadURLsMac = async (): Promise<{
  'mac-arm': string;
  'mac-intel': string;
}> => {
  const getUrl = async (arch: 'arm64' | 'x64'): Promise<string> => {
    const archUrl = `${REACT_APP_DESKTOP_APP_S3_BUCKET_URL}/darwin/${arch}`;
    const url = `${archUrl}/RELEASES.json`;
    const response = await fetch(url);
    if (response.status !== 200) {
      throw new Error('Error fetching releases JSON');
    }
    const { currentRelease } = await response.json();
    return `${archUrl}/Electric Desktop-${currentRelease}-${arch}.dmg`;
  };
  return {
    'mac-arm': await getUrl('arm64'),
    'mac-intel': await getUrl('x64'),
  };
};

const getDownloadLinks = async (): Promise<DownloadURLsMap> => {
  return {
    ...(await getDownloadURLsMac()),
    win: await getDownloadURLWindows(),
  };
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  cb: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event?.target as Node)) {
        cb();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb]);
}

function getSystemVersion(): Version {
  if (!getIsMac()) {
    return 'win';
  }

  if (getMacArchitecture() === ARCHITECTURES.ARM) {
    return 'mac-arm';
  }

  return 'mac-intel';
}

const DownloadButton = ({ version, downloadUrl, children }) => {
  return (
    <a
      id={`electric-desktop-app-download-${version}`}
      href={downloadUrl ?? '#'}
      target="_blank"
      rel="noreferrer">
      {children}
    </a>
  );
};

const DownloadDropdownButton = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openDropdownClass = isDropdownOpen ? 'dropdown-open' : '';

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setIsDropdownOpen(false);
  });

  const [downloadUrlMap, setDownloadUrlMap] = useState<DownloadURLsMap | null>(
    null
  );
  useEffect(() => {
    const getDownloadLinksAndSetState = async () => {
      const downloadLinks = await getDownloadLinks();
      setDownloadUrlMap(downloadLinks);
    };

    getDownloadLinksAndSetState();
  }, [setDownloadUrlMap]);

  const buttonMap = {
    'mac-arm': (
      <DownloadButton
        key="mac-arm"
        version="mac-arm"
        downloadUrl={downloadUrlMap?.['mac-arm'] ?? null}>
        Download for Mac - Apple Silicon
      </DownloadButton>
    ),
    'mac-intel': (
      <DownloadButton
        key="mac-intel"
        version="mac-intel"
        downloadUrl={downloadUrlMap?.['mac-intel']}>
        Download for Mac - Intel
      </DownloadButton>
    ),
    win: (
      <DownloadButton
        key="win"
        version="win"
        downloadUrl={downloadUrlMap?.['win']}>
        Download for Windows
      </DownloadButton>
    ),
  };

  const preferredVersion = useMemo(() => getSystemVersion(), []);
  const PreferredButton = buttonMap[preferredVersion];
  const otherVersions = Object.keys(buttonMap).filter(
    (version) => version !== preferredVersion
  );

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={`download-button ${openDropdownClass}`} ref={wrapperRef}>
      {PreferredButton}
      <button className="dropdown-toggle" onClick={handleDropdownClick}>
        <img
          alt="desktop app type toggle dropdown"
          src="/assets/images/chevron-down.svg"
          width="20px"
        />
      </button>
      <div className={`alternative-download-options ${openDropdownClass}`}>
        {otherVersions.map((version) => buttonMap[version])}
      </div>
    </div>
  );
};

export default DownloadDropdownButton;
