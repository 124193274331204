import React, { useEffect } from 'react';
import './TriggerDesktopAppOpenPage.css';

const TriggerDesktopAppOpenPage = () => {
  // Redirect user to electric-desktop://mdm when the page loads
  useEffect(() => {
    window.location.href = 'electric-desktop://mdm';
  });
  return (
    <div className="app-div">
      <div className="app-text-container">
        <img
          alt="desktop app download icon"
          src="/assets/images/appDownload.svg"
        />
        <h1 className="app-h1 app-text">Opening Electric Desktop</h1>
        <p className="app-p app-text">
          Click Open Electric Desktop on the dialog shown by your browser. If
          you don’t see a dialog, click the download button below to download
          the installer, then run it to install the app.
        </p>
      </div>
    </div>
  );
};

export default TriggerDesktopAppOpenPage;
